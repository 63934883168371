@import "~bootstrap/scss/bootstrap";
@import "_fonts";
@import "_mixins";

/*==== GLOBAL ====*/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  font-family: $roboto;
  font-weight: 700;
  color: #474747;
}

.wrapper {
  overflow: hidden;
  position: relative;
  padding-bottom: 80px;
  background: url("../images/left_circles.png") no-repeat 0 60%,
  url("../images/right_circles.png") no-repeat 100% 77%;

  @include res(1500){
    background: none;
  }
  @include res(768){
    padding-bottom: 114px;
  }
}

.container {
  max-width: 1210px;
  padding-left: 20px;
  padding-right: 20px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.9);
  padding: 20px 0;
  z-index: 100;

  @include res(768){
    background: #fff;
    height: 40px;
    position: static;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  }

  .container{

    @include res(768){
      justify-content: center!important;
    }
  }

  .logo {
    width: 220px;

    @include res(768){
      width: 100px;
    }
  }

  &__links {

    @include res(768){
      position: absolute;
      bottom: 50px;
      left: 0;
      width: 100%;
      height: 64px;
      padding: 0 10px;
      border-top: 1px solid rgba(71,71,71,.15);
      justify-content: center!important;
    }
    @include res(480){
      justify-content: center!important;
    }

    a {
      position: relative;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 24px;
      padding-left: 40px;
      color: #474747;

      @include res(768){
        font-size: 12px;
        line-height: 18px;
        padding-left: 26px;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -12px;
        height: 24px;
        width: 24px;
        background-size: auto 100%!important;

        @include res(768){
          height: 16px;
          width: 16px;
          margin-top: -8px;
        }
      }

      & + a {
        margin-left: 36px;
      }

      &.phone {
        &:before{
          background: url("../images/contact_icon.png") no-repeat 0 50%;
        }
      }

      &.mentions {
        &:before{
          background: url("../images/legales_icon.png") no-repeat 0 50%;
        }
      }
    }
  }
}

.main-block {
  background: url("../images/top_right_pic.jpg") no-repeat 100% 50%;
  background-size: auto 100%;
  padding-top: 158px;
  padding-bottom: 33px;
  margin-bottom: 30px;

  @include res(1960){
    background-position-x: 125%;
  }
  @include res(1640){
    background-position-x: 135%;
  }
  @include res(1530){
    background-position-x: 100%;
    background-size: 50% auto;
    background-position-y: 0;
  }
  @include res(991){
    padding-top: 230px;
  }
  @include res(768){
    padding-top: 40px;
    background: none;
    text-align: center;
  }
  @include res(320){
    padding-top: 210px;
    background: url("../images/top_pic_mobile.jpg") no-repeat 50% 0;
    background-size: 100% auto;
  }

  .title21 {
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    text-transform: uppercase;
    margin-bottom: 12px;

    @include res(767){
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 5px;
    }
  }

  h1 {
    font-size: 54px;
    font-weight: 700;
    line-height: 64px;
    text-transform: uppercase;
    margin-bottom: 10px;

    @include res(991){
      font-size: 44px;
      line-height: 58px;
    }
    @include res(768){
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 0;
    }
  }

  h2.title21 {
    font-style: italic;
    margin-bottom: 28px;
    text-transform: none;
    font-weight: 300;

    @include res(768){
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 25px;
    }
  }
}

.top-button {
  text-transform: uppercase;
  color: #fff;
  max-width: 100%;
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
  box-shadow: 0 20px 45px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #01addf;
  padding: 18px 85px;
  display: inline-block;
  transition: .3s;

  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: #01bdf8;
  }
}

.iframe {
  margin-bottom: 40px;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  &__inner {
    border-top: 1px solid rgba(71, 71, 71, .15);
    height: 80px;

    @include res(768){
      height: 50px;
    }
  }

  img {
    width: 134px;
  }
}

.modal-title{
  font-weight: 700;
}

.modal-body {
  font-weight: 400;
}
